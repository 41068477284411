import React, { useState } from "react";
import fetchJson from "@/lib/fetchJson";
import { KeyedMutator } from "swr";
import { Box, Button, Flex, Table, Text, TextArea, TextField } from "@radix-ui/themes";
import { IconPlus } from "@tabler/icons-react";
import * as Dialog from "@/components/CustomComponents/Dialog"

interface Props {
  mutate: KeyedMutator<Campaign>;
  campaignId: number;
  type: string;
}

const NewMassImport = ({ mutate, campaignId, type}: Props) => {
  const add = async () => {
    setLoading(true);
    const data = url.split("\n");
    const res = await fetchJson<Post[]>(`/post/mass`, {
      method: "POST",
      body: JSON.stringify({ data, campaignId }),
    });
    setUrl("");
    setResults(res);
    mutate();
    setTab("results");
    setLoading(false);
    setInterval(mutate, 5000);
  };

  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [tab, setTab] = useState("import");

  const message = (item: any) => {
    if (item.status === "success") {
      return "Post added successfully";
    }
    if (item.status === "error") {
      return "Not a valid link";
    }
    if (item.status === "exists") {
      return (
        <span>
          Post already exists in{" "}
          <a href={`/campaign/${item.campaign.id}`} className="underline">
            {item.campaign.name}
          </a>
        </span>
      );
    }
  };

  const color = (status: string) => {
    if (status === "success") {
      return "text-green-500";
    }
    if (status === "error") {
      return "text-red-500";
    }
    return "text-yellow-500";
  };

  return (
<>
    <Dialog.Root>
      <Dialog.Trigger>
        <Button>
          <IconPlus className="icon-accent"/>
          Add
        </Button>
      </Dialog.Trigger>
<Dialog.Portal>
  <Dialog.Content style={{maxWidth: "500px"}}>
    {type === "post" ? <Dialog.Title>Add Posts</Dialog.Title> : <Dialog.Title>Add Sounds or Hashtags</Dialog.Title>}
    {tab === "import" ? 
    (<Flex direction="column" gap="3">
      <Box>
        {type == "post" ? <Text as="div" size="2" mb="1" weight="bold">
        Paste in a list of post URLs, one per line:
        </Text>: <Text as="div" size="2" mb="1" weight="bold">
        Paste in a list of sound URLs or hashtags, one per line:
        </Text>}
        <TextArea
          resize="vertical"
          placeholder="Enter list of items"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </Box>
      <Button onClick={() => add()} loading={loading}>
        Import
      </Button>
    </Flex>): (<Flex>
            {results.length >= 0 && (
                <Table.Root>
                  <Table.Header>
                    <Table.Row>
                    <Table.ColumnHeaderCell>Item</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {results.map((item: any) => (
                      <Table.Row
                        key={item.id}
                      >
                        <Table.Cell maxWidth="350px"
                        >
                          <a
                            href={item.url}
                            target="_blank"
                            className="underline"
                          >
                            {item.url.length > 40
                              ? item.url.substring(0, 40) + "..."
                              : item.url}
                          </a>
                        </Table.Cell>
                        <Table.Cell minWidth="150px" className={`${color(item.status)}`}>
                          {message(item)}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table.Root>
            )}
          </Flex>)}

    <Flex gap="3" mt="4" justify="end">
      <Dialog.Close>
        <Button 
        variant="soft"
        color="gray"
        onClick={() => {
          setUrl(""),
          setTab("import")}}>
            Close
        </Button>
      </Dialog.Close>
    </Flex>
  </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
    </>
  );
};

export default NewMassImport;
